import PreCSS from "../src/utils/preRenderCSS";

export default ({ lang }: { lang: string }) => (
  <>
    <PreCSS />
    <div style={{ minHeight: "500px" }}>
      {lang === "ja"
        ? "エラーが発生しました。トップページに戻ってください。"
        : lang === "en"
        ? "We had an error loading this page. Please go back to the top."
        : ""}
    </div>
  </>
);
